import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./imageview.css";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const Deposits = () => {
  const [user, setUser] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  // const profle = () => {

  //   const access_token = localStorage.getItem("mjLudoTokenDash")
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`
  //   }
  //   axios.get(baseUrl+`temp/deposit/pending`, { headers })
  //     .then((res) => {
  //       setUser(res.data)
  //       $('table').dataTable();
  //       imageViewer();

  //       //   console.log(user)
  //     })
  // }

  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);
  const [findByStatus, setFindByStatus] = useState(0);

  //   searching handler
  const searchHandler = (event) => {
    let key = event.target.value;

    setSearchList(key);
  };
  //   search by status handler
  const searchByStatus = (event) => {
    let key = event.target.value;
    setFindByStatus(key);
  };

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const profle = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(
        baseUrl +
          `temp/deposit/pending?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&_status=${findByStatus}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data);
        setNumberOfPages(res.data.totalPages);
        imageViewer();
        // $('table').dataTable();
      });
  };

  const update = async (id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(
        baseUrl + `temp/deposite/${id}`,
        {
          status: "success",
        },
        { headers }
      )
      .then((res) => {
        profle();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const cancelledData = async (id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .delete(
        baseUrl + `temp/deposit/delete/${id}`,

        { headers }
      )
      .then((res) => {
        profle();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //   onClick={() => {
  //                             // window.open(item.Document)
  //                             console.log(item.Document)
  //                             const ss = document.getElementById(`ss${index}`)
  //                             const width = ss.style.width
  //                             const height = ss.style.height
  //                             if (width === '4rem') {
  //                               ss.style.width = '100%'
  //                               ss.style.height = '100%'
  //                             }
  //                             else {
  //                               ss.style.width = '4rem'
  //                               ss.style.height = '4rem'
  //                             }
  //                           }}

  function imageViewer() {
    let imgs = document.getElementsByClassName("img"),
      out = document.getElementsByClassName("img-out")[0];
    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains("el")) {
        imgs[i].classList.add("el");
        imgs[i].addEventListener("click", lightImage);
        function lightImage() {
          let container = document.getElementsByClassName("img-panel")[i];
          container.classList.toggle("img-panel__selct");
        }

        imgs[i].addEventListener("click", openImage);
        function openImage() {
          let imgElement = document.createElement("img"),
            imgWrapper = document.createElement("div"),
            imgClose = document.createElement("div"),
            container = document.getElementsByClassName("img-panel")[i];
          container.classList.add("img-panel__selct");
          imgElement.setAttribute("class", "image__selected");
          imgElement.src = imgs[i].src;
          imgWrapper.setAttribute("class", "img-wrapper");
          imgClose.setAttribute("class", "img-close");
          imgWrapper.appendChild(imgElement);
          imgWrapper.appendChild(imgClose);

          setTimeout(function () {
            imgWrapper.classList.add("img-wrapper__initial");
            imgElement.classList.add("img-selected-initial");
          }, 50);

          out.appendChild(imgWrapper);
          imgClose.addEventListener("click", function () {
            container.classList.remove("img-panel__selct");
            out.removeChild(imgWrapper);
          });
        }
      }
    }
  }
  useEffect(() => {
    profle();
  }, []);

  if (user == undefined) {
    return null;
  }

  return (
    // <div className="row ">
    //   <div className="col-12 grid-margin">
    //     <div className="card">
    //       <div className="card-body">
    //         <div className="img-out"></div>
    //         <h4 className="card-title">Deposit Request</h4>
    //         <div className="table-responsive">
    //           <table className="table">
    //             <thead>
    // <tr>
    //   <th>#</th>
    //   <th> ID</th>
    //   <th> Username</th>
    //   <th> Amount </th>
    //   <th> screenshot </th>
    //   <th> Action </th>
    // </tr>
    //             </thead>

    //             <tbody>
    //               {user && user.map((item, index) => (
    // <tr key={item._id}>
    //   <td>{index + 1}</td>
    //   <td>{item._id}</td>
    //   <td>{item.user.Name}</td>
    //   <td>{item.amount}</td>
    //   <td>
    //     <div className="img-panel">

    //       <img className="img" src={baseUrl+`${item.front}`} style={{
    //         borderRadius: '5px',
    //         width: '4rem',
    //         height: '4rem',
    //       }}
    //         id={`ss${index}`}
    //       />
    //     </div>
    //   </td>
    //   <td>
    //     {item.status == "pending" && <button className="btn btn-primary mr-2" onClick={() => update(item._id)} >Approve</button>}

    //     {item.status == "success" && <button className="btn btn-success mr-2" >success</button>}
    //     {item.status == "pending" && <button className="btn btn-danger mr-2" onClick={() => cancelledData(item._id)} >delete</button>}
    //   </td>
    //   {/* <td> */}
    // </tr>

    //               ))
    //               }
    //             </tbody>

    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="row ">
      <div className="col-12 grid-margin">
        <div className="card ">
          <div className="card-body text-light">
            <h4 className="card-title">Deposit Request</h4>

            {/* searching */}
            <div className="row">
              <div className="col-md-4">
                <select
                  className="form-control col-sm-3 m-2"
                  id="searchType"
                  name="searchtype"
                  onChange={(e) => setSearchType(e.target.value)}
                >
                  <option value="0">Select Search by</option>
                  <option value="Name">Name</option>
                  <option value="Phone">Phone</option>
                  <option value="_id">TxnId</option>
                </select>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control col-sm-4 m-2"
                  onChange={searchHandler}
                />
              </div>
              <div className="col-md-1 ms-auto">
                <select
                  className="form-control col-sm-1 m-1 bg-dark text-light"
                  id="pagelimit"
                  name="pagelimit"
                  onChange={setpageLimit}
                >
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              {/* <select className='form-control col-sm-3 m-2' id='findByStatus' name='findByStatus' onChange={searchByStatus}>
                  <option value="0">Search Status</option>
                  <option value="FAILED">FAILED</option>
                  <option value="Pending">Pending</option>
                  <option value="pending">pending</option>
                  <option value="PAID">PAID</option>
                  <option value="pending">pending</option>
                  <option value="checkin">Checkin</option>
                </select> */}
            </div>

            <div className="table-responsive">
              <table className="table text-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th> ID</th>
                    <th> Username</th>
                    <th> Amount </th>
                    <th> screenshot </th>
                    <th> Action </th>
                  </tr>
                </thead>

                <tbody>
                  {user &&
                    user.map((data, key) => {
                      var id = data._id.toString(),
                        ctr = 0;
                      var timestamp = id.slice(ctr, (ctr += 8));
                      var machineID = id.slice(ctr, (ctr += 6));
                      var processID = id.slice(ctr, (ctr += 4));
                      var counter = id.slice(ctr, (ctr += 6));
                      //console.log("id:", id);
                      //console.log("timestamp:", parseInt(timestamp, 16));
                      //console.log("machineID:", parseInt(machineID, 16));
                      //console.log("processID:", parseInt(processID, 16));
                      var counterid = parseInt(timestamp, 16);
                      let currentTime = Date.now();
                      let gameCreatedAt = new Date(data.createdAt).getTime();

                      return (
                        <tr key={data._id}>
                          <td>{key + 1}</td>
                          <td>{data._id}</td>
                          <td>{data.user.Name}</td>
                          <td>{data.amount}</td>
                          <td>
                            <div className="img-panel">
                              <img
                                className="img"
                                src={baseUrl + `${data.front}`}
                                style={{
                                  borderRadius: "5px",
                                  width: "4rem",
                                  height: "4rem",
                                }}
                                id={`ss${key}`}
                              />
                            </div>
                          </td>
                          <td>
                            {data.status == "pending" && (
                              <button
                                className="btn btn-primary mr-2"
                                onClick={() => update(data._id)}
                              >
                                Approve
                              </button>
                            )}

                            {data.status == "success" && (
                              <button className="btn btn-success mr-2">
                                success
                              </button>
                            )}
                            {data.status == "pending" && (
                              <button
                                className="btn btn-danger mr-2"
                                onClick={() => cancelledData(data._id)}
                              >
                                delete
                              </button>
                            )}
                          </td>
                          {/* <td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposits;
