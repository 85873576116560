import React, { useState } from "react";
import axios from "axios";

const DeshWithdraw = () => {
  const [totalWithdrawl, setTotalWithdrawl] = useState(0);
  const [totalPendingWithdrawl, setTotalPendingWithdrawl] = useState(0);
  const [totalRejectedWithdrawl, setTotalRejectedWithdrawl] = useState(0);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const totalWithdrawlfunc = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `total/withdraw`, { headers }).then((res) => {
      setTotalWithdrawl(res.data);
    });
  };

  const totalPendingWithdrawlfunc = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `count/new/withdrawl`, { headers }).then((res) => {
      setTotalPendingWithdrawl(res.data);
    });
  };

  const totalRejectedWithdrawlfunc = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `count/rejected/withdrawl`, { headers }).then((res) => {
      setTotalRejectedWithdrawl(res.data);
    });
  };

  return (
    <>
      <div className=""></div>
      <div className="row">
        <h2 className="box-desing-h2">Today Withdraw Details :-</h2>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Total Withdraw</h3>
            <span>4099878</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Cancel Withdraw</h3>
            <span>4099878</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Success Withdraw</h3>
            <span>4099878</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Pending Withdraw</h3>
            <span>4099878</span>
          </div>
        </div>
      </div>
      <div className="row">
        <h2 className="box-desing-h2">Total Withdraw Details :-</h2>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Total Withdraw Request</h3>
            <span>{totalWithdrawl && totalWithdrawl.count}</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Total Withdraw</h3>
            <span>{totalWithdrawl && totalWithdrawl.data}</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Cancel Withdraw</h3>
            <span>
              {totalRejectedWithdrawl && totalRejectedWithdrawl.count}
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-desing">
            <h3>Pending Withdraw</h3>
            <span>{totalPendingWithdrawl && totalPendingWithdrawl.count}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeshWithdraw;
