import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useLocation, Link, useHistory } from "react-router-dom";
import Loader from "react-js-loader";
import Swal from "sweetalert2";
import admindata from "../../components/admindata";
import { DateObject } from "react-multi-date-picker";
import bigInt from "big-integer";
import { useSelector } from "react-redux";
import "./invoice.css";
import html2pdf from "html2pdf.js";
const $ = require("jquery");
$.Datatable = require("datatables.net");

export default function View(props) {
  const invoiceRef = useRef();
  const access_token = localStorage.getItem("mjLudoTokenDash");
  const [user1, setUser1] = useState();
  const [fraudData, setFraudData] = useState(null);
  const [showFraud, setShowFraud] = useState(false);
  const getOnUsers = useSelector((state) => state.home.getOnUsers);
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsTag, setShowInputsTag] = useState(false);
  const [formData, setFormData] = useState({
    Wallet_balance: "",
    withdrawAmount: "",
    wonAmount: "",
    loseAmount: "",
  });

  const history = useHistory();

  // const getUser1 = () => {
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`,
  //   };
  //   axios
  //     .get(`${base_Url}/me`, { headers })
  //     .then((res) => {
  //       setUser1(res.data);
  //       // toast("Login Success")
  //     })
  //     // console.log("first")
  //     .catch((e) => {
  //       if (e.response.status == 401) {
  //         localStorage.removeItem("mjLudoTokenDash");
  //         // history.pushState("/login")
  //       }
  //     });
  // };

  useEffect(() => {
    if (getOnUsers) {
      setUser1(getOnUsers);
    }
  }, [getOnUsers]);

  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const [user, setUser] = useState();
  const [item, setVerify] = useState();
  const [challenge, setchallenge] = useState();
  const [txn, setTxn] = useState();
  const [txnwith, setTxnwith] = useState();
  const [referral, setReferral] = useState();
  const [kyc, setKyc] = useState();
  const [merged, setMerged] = useState([]);
  let [mismatchValue, setmismatchValue] = useState(0);
  let [HoldBalance, setHoldBalance] = useState(0);
  const [referCount, setRefercount] = useState([]);

  const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days"),
  ]);

  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);
  const [findByStatus, setFindByStatus] = useState(0);

  //   searching handler
  const searchHandler = (event) => {
    let key = event.target.value;

    setSearchList(key);
  };
  //   search by status handler
  const searchByStatus = (event) => {
    let key = event.target.value;
    setFindByStatus(key);
  };

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  // console.log(path)
  const getUser = () => {
    setLoading(true);
    let access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `get_user/${path}`, { headers })
      .then((res) => {
        setLoading(false);
        setUser(res.data);
        Allrefer(res.data.referral_code);
        const amountDeposit = res.data.totalDeposit + res.data.depositCashback;
        setmismatchValue(
          res.data.Wallet_balance -
            (res.data.wonAmount -
              res.data.loseAmount +
              amountDeposit +
              res.data.referral_earning +
              res.data.totalBonus -
              (res.data.totalWithdrawl +
                res.data.referral_wallet +
                res.data.withdraw_holdbalance +
                res.data.hold_balance +
                res.data.totalPenalty))
        );
        setHoldBalance(res.data.hold_balance);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getUserFraud = () => {
    setLoading(true);
    let access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `get_user/fraud-list/${path}`, { headers })
      .then((res) => {
        setFraudData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const checkUserTotalValue = () => {
    setLoading(true);
    let access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `check-withdraw-balance/${path}`, { headers })
      .then((res) => {
        setLoading(false);
        if (res.data.error) {
          Swal.fire({
            title: res.data.message,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else {
          setShowInputsTag(true);
          setFormData({
            wonAmount: res?.data?.data?.gameWon,
            loseAmount: res?.data?.data?.gameLoss,
            Wallet_balance: res?.data?.data?.amount,
          });
          Swal.fire({
            title: `Remaing amount:${res?.data?.data?.amount}, gameWon:${res?.data?.data?.gameWon} ,gameLoss:${res?.data?.data?.gameLoss}, totalDeposit:${res?.data?.data?.totalDeposit}, totalWithdraw:${res?.data?.data?.totalWithdraw}`,
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const Allrefer = async (id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `admin-referral/code/${id}`, { headers })
      .then((res) => {
        setRefercount(res.data);
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const Allchallenge = async () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          // `get_challange/user/${path}/?page=${pageNumber}&_limit=${limit}`,
          `get_challange/user/${path}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        setTxn(undefined);
        setTxnwith(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setMerged(undefined);
        setchallenge(res.data.data);
        setNumberOfPages(res.data.length);

        // console.log(path)
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const transactionHis = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl + `txn_history/user/${path}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setMerged(undefined);
        setTxn(res.data.data);
        setNumberOfPages(res.data.length);
      })
      .catch((e) => {});
  };

  const withdrawalHis = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `txnwith_history/user/${path}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        setTxn(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setMerged(undefined);
        setTxnwith(res.data.data);
        setNumberOfPages(res.data.length);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour24: true,
      minute: "numeric",
    });
    return newDate;
  };
  const referralHis = async () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(
        baseUrl +
          `admin-referral/code/winn/${
            parseFloat(user.referral_code) === parseFloat(607781)
              ? null
              : user.referral_code
          }?page=${pageNumber}&_limit=${limit}`,
        {
          headers,
        }
      )
      .then((res) => {
        setchallenge(undefined);
        setTxn(undefined);
        setTxnwith(undefined);
        setKyc(undefined);
        setMerged(undefined);
        setReferral(res.data.data);
        setNumberOfPages(res.data.length);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getKyc = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `admin/user/kyc/${path}`, { headers }).then((res) => {
      setTxn(undefined);
      setTxnwith(undefined);
      setReferral(undefined);
      setchallenge(undefined);
      setKyc(res.data);
      setMerged(undefined);
      // console.log(res.data);
      $("table").dataTable();
      imageViewer();
    });
  };

  const all_history = () => {
    navigate.push(`/user/allhistory/${path}`);
  };

  function imageViewer() {
    let imgs = document.getElementsByClassName("img"),
      out = document.getElementsByClassName("img-out")[0];
    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains("el")) {
        imgs[i].classList.add("el");
        imgs[i].addEventListener("click", lightImage);
        function lightImage() {
          let container = document.getElementsByClassName("img-panel")[i];
          container.classList.toggle("img-panel__selct");
        }

        imgs[i].addEventListener("click", openImage);
        function openImage() {
          let imgElement = document.createElement("img"),
            imgWrapper = document.createElement("div"),
            imgClose = document.createElement("div"),
            container = document.getElementsByClassName("img-panel")[i];
          container.classList.add("img-panel__selct");
          imgElement.setAttribute("class", "image__selected");
          imgElement.src = imgs[i].src;
          imgWrapper.setAttribute("class", "img-wrapper");
          imgClose.setAttribute("class", "img-close");
          imgWrapper.appendChild(imgElement);
          imgWrapper.appendChild(imgClose);

          setTimeout(function () {
            imgWrapper.classList.add("img-wrapper__initial");
            imgElement.classList.add("img-selected-initial");
          }, 50);

          out.appendChild(imgWrapper);
          imgClose.addEventListener("click", function () {
            container.classList.remove("img-panel__selct");
            out.removeChild(imgWrapper);
          });
        }
      }
    }
  }
  const update = (Id) => {
    setLoading(true);
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(
        baseUrl + `aadharcard/${Id}`,
        { verified: "verified" },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        getUser();
        getKyc();
      });
  };
  const handleDelete = (Id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (window.confirm("Are you sure you want to delete?")) {
      axios.delete(baseUrl + `aadharcard/${Id}`, { headers }).then((res) => {
        getKyc();
      });
    } else {
    }
  };

  const updateMismatch = (Id) => {
    setLoading(true);

    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `user/missmatch/clear/${Id}`, { headers }, { headers })
      .then((res) => {
        setLoading(false);
        getUser();
        alert("Updated Successfully");
      });
  };

  const updateHold = (Id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `user/Hold/clear/${Id}`, { headers }, { headers })
      .then((res) => {
        getUser();
        alert("Updated Successfully");
      });
  };

  const updateCheckFraud = async (Id, status) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    var text1;
    var { value: text1 } = await Swal.fire({
      input: "textarea",
      inputLabel: "Fraud Reason",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
    });
    if (text1) {
      axios
        .post(
          baseUrl + `user/fraud/${Id}`,
          { reason: text1, check: status },
          { headers }
        )
        .then((res) => {
          getUser();
          alert("Updated Successfully");
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Enter Reason",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const updateCheckNameChange = async (Id, status) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    var text1;
    var { value: text1 } = await Swal.fire({
      input: "textarea",
      inputLabel: "Name Block Reason",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
    });
    if (text1) {
      axios
        .post(
          baseUrl + `user/name/${Id}`,
          { reason: text1, check: status },
          { headers }
        )
        .then((res) => {
          getUser();
          alert("Updated Successfully");
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Enter Reason",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const otpRemoveHanlder = async (Id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .post(baseUrl + `otp/remove/${Id}`, { number: "" }, { headers })
      .then((res) => {
        alert(res.data);
      });
  };

  const checkfailedpayout = (txn_id, referenceId) => {
    setLoading(true);
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `payout/response/api`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        const icon = res.data.status == "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status == "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";
        getUser();
        withdrawalHis();
        alert(title);
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status == 401) {
          localStorage.removeItem("mjLudoTokenDash");
          localStorage.removeItem("mjLudoTokenDash");
          window.location.reload();
        }
      });
  };

  const deletedata = (Id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `aadharcard/${Id}`, { verified: "reject" }, { headers })
      .then((res) => {
        getUser();
        getKyc();
      });
  };

  const amountDeposit = user?.totalDeposit + user?.depositCashback;

  async function commonApi() {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const [result1, result2, result3] = await Promise.all([
      axios.get(
        baseUrl +
          `get_challange/user/${path}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      ),
      axios.get(
        baseUrl + `txn_history/user/${path}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      ),
      axios.get(
        baseUrl +
          `txnwith_history/user/${path}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      ),
    ]);
    setTxn(undefined);
    setTxnwith(undefined);
    setReferral(undefined);
    setchallenge(undefined);

    setKyc(undefined);
    setNumberOfPages(result1?.data?.length);
    const data1 =
      result1?.data &&
      result1?.data?.data?.map((data) => {
        return {
          ID: data._id,
          amount: data.Game_Ammount,
          status: data.Status,
          date: dateFormat(data.updatedAt).split(",")[0],
          Win_loose_amount: data.Winner
            ? user?._id == data.Winner?._id
              ? "+" + data.winnAmount
              : "-" + data.Game_Ammount
            : user?._id == data.Created_by?._id
            ? data.winnAmount
            : "-" + data.winnAmount,
          closing_balance: data.Winner
            ? user?._id == data.Winner?._id
              ? data.Winner_closingbalance
              : data.Loser_closingbalance
            : user?._id == data.Created_by?._id
            ? data.Loser_closingbalance
            : data.Winner_closingbalance,
        };
      });
    const data2 =
      result2?.data &&
      result2?.data?.data?.map((data) => {
        return {
          ID: data._id,
          amount: data.amount,
          status: data.status,
          date: dateFormat(data.updatedAt).split(",")[0],
          Req_type: data.Req_type,
          closing_balance: data.closing_balance,
          Win_loose_amount: 0,
        };
      });
    const data3 =
      result3?.data &&
      result3?.data?.data?.map((data) => {
        return {
          ID: data._id,
          amount: data.amount,
          status: data.status,
          date: dateFormat(data.updatedAt).split(",")[0],
          Req_type: data.Req_type,
          closing_balance: data.closing_balance,
          Win_loose_amount: 0,
        };
      });
    const merge = data1.concat(data2, data3);
    setMerged(merge);
    $("table").dataTable();
  }

  const Referal = () => {
    Swal.fire({
      title: "Enter Referral code",
      inputValue: user.referral,
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(
          baseUrl + `user/updaterefferal/${login ? login : "referral"}/${path}`,
          {
            method: "get",
            headers: new Headers({
              Authorization: localStorage.getItem("mjLudoTokenDash"),
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            getUser();
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.status) {
          Swal.fire({
            title: `${result.value.msg}`,
            imageUrl: result.value.avatar_url,
          });
        } else {
          Swal.fire({
            title: `${result.value.msg}`,
            imageUrl: result.value.avatar_url,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (merged) {
    }
    if (referral) {
      referralHis();
    }
    if (txnwith) {
      withdrawalHis();
    }
    if (txn) {
      transactionHis();
    }
    if (challenge) {
      Allchallenge();
    }
  }, [pageNumber]);

  useEffect(() => {
    Allchallenge();
    getUser();
  }, []);

  useEffect(() => {
    if (showFraud) {
      getUserFraud();
    }
  }, [showFraud]);

  useEffect(() => {
    setMerged(null);
  }, []);

  const Invoice = (data) => {
    const userData = { data };
    // setUser(userData);
    history.push("/invoice", { userData });
  };

  // const generateKycPDF = () => {
  //   const element = invoiceRef.current;
  //   html2pdf()
  //     .from(element)
  //     .set({
  //       margin: 0.1,
  //       filename: "kyc-details.pdf",
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //       pagebreak: { mode: ["avoid-all"] },
  //     })
  //     .save();
  // };

  const generateKycPDF = () => {
    const kycData = kyc?.find((data, index) => data.verified === "verified");
    const hiddenDiv = document.createElement("div");
    hiddenDiv.innerHTML = `
      <div class="kyc" style="border: 1px solid gray; padding: 5px 50px; max-width: 800px; margin: auto;">
        <table style="width: 100%; border-collapse: collapse;">
          <tbody>
            <tr>
              <td style="border-bottom: 1px solid gray; width: 100px;">
                <img src="/cersailogo.jpg" alt="logo" style="width: 80px; height: 80px; padding-bottom: 5px;" />
              </td>
              <td style="font-size: 20px; font-weight: 600; padding-left: 0px; border-bottom: 1px solid gray;">
                Central KYC Registry
              </td>
            </tr>
          </tbody>
        </table>
        <table style="margin-top: 5px; border-collapse: collapse;">
          <tbody>
            <tr>
              <td style="width: 150px; vertical-align: top;">
                <img src="data:image/jpeg;base64,${kycData?.allKycData?.photo_link}" alt="userimg" style="width: 150px;margin-right: 20px;padding-right:30px;" />
              </td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td style="width: 140px; padding-bottom: 20px;">KYC identifier</td>
                      <td style="vertical-align: top;">:${kycData?.Number}</td>
                    </tr>
                    <tr>
                      <td style="width: 140px; padding-bottom: 20px;">Name</td>
                      <td style="vertical-align: top;">:${kycData?.Name}</td>
                    </tr>
                    <tr>
                      <td style="width: 140px; padding-bottom: 20px;">Date of Birth</td>
                      <td style="vertical-align: top;">:${kycData?.DOB}</td>
                    </tr>
                    <tr>
                      <td style="width: 140px; padding-bottom: 20px;">Email ID</td>
                      <td style="vertical-align: top;">:${user.Email}</td>
                    </tr>
                    <tr>
                      <td style="width: 140px; padding-bottom: 20px;">Mobile Number</td>
                      <td style="vertical-align: top;">:${user.Phone}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr style="width: 100%;">
              <td style="width: 50%; padding-right: 15px; padding-bottom: 30px; word-wrap: break-word; white-space: normal; overflow-wrap: break-word;">
                Officially valid document submitted for proof of identify & address
              </td>
              <td style="width: 50%; vertical-align: top; padding-bottom: 30px; word-wrap: break-word; white-space: normal; overflow-wrap: break-word;">
                :Proof Of Possession Of Aadhaar, E-KYC Authentication
              </td>
            </tr>
          </tbody>
        </table>
        <table style="width: 70%; margin-top: 30px;">
          <tbody>
            <tr>
              <td>Address as mentioned in proof of identify & address:
                <p>${kycData?.Address}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table style="width: 70%; margin-top: 50px;">
          <tbody>
            <tr>
              <td>Current Address:
                <p>${kycData?.Address}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    `;

    // Temporarily add the hidden div to the DOM
    document.body.appendChild(hiddenDiv);

    // Convert the hidden div to canvas and generate PDF
    html2pdf()
      .from(hiddenDiv)
      .set({
        margin: 0.1,
        filename: "kyc-details.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
      })
      .save()
      .then(() => {
        // Remove the hidden div after PDF is generated
        document.body.removeChild(hiddenDiv);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    let access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = formData;
    axios
      .post(baseUrl + `update-user-values/${path}`, data, {
        headers,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status === false) {
          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: res.data.msg,
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return loading ? (
    <>
      <div className="App">
        <div className="item dashboaed_loader">
          <Loader
            type="bubble-ping"
            bgColor={"#fff"}
            title={"Loading Please Wait"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      {Boolean(user) && (
        <>
          <div className="img-out"></div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="card shadow-lg h-100 ">
                <div className="card-header">User Details</div>
                <ul className="list-group list-group-flush">
                  {user && user.avatar && (
                    <li className="list-group-item img-panel">
                      Profile Pic :
                      <img
                        className="img"
                        src={baseUrl + `${user.avatar}`}
                        alt="Profile"
                        style={{
                          borderRadius: "5px",
                          width: "4rem",
                          height: "4rem",
                        }}
                      />{" "}
                    </li>
                  )}
                  <li
                    className={`list-group-item ${
                      parseFloat(user.checkFraud) === parseFloat(1)
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    Name : {user.Name}
                  </li>
                  <li
                    className={`list-group-item ${
                      parseFloat(user.checkFraud) === parseFloat(1)
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    _id : {user?._id}
                  </li>
                  <li
                    className={`list-group-item ${
                      parseFloat(user.checkFraud) === parseFloat(1)
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    UniqueId : {user.userUniqueId}
                  </li>

                  <li className="list-group-item">
                    verified :{" "}
                    <span
                      className={`badge badge-pill ${
                        user.verified == "verified"
                          ? "badge-success"
                          : "badge-danger"
                      }`}
                    >
                      {user.verified}
                    </span>
                  </li>
                  {admindata?.user?.user_type == "Admin" ||
                  admindata?.user?.profile?._id == "65b788e5b34c045962581b27" ||
                  admindata?.user?.updateLegalData === true ? (
                    <li className="list-group-item">Phone : {user.Phone}</li>
                  ) : (
                    <li className="list-group-item">
                      {" "}
                      Phone :{" "}
                      {`${"********" + user.Phone.toString().slice(-2)}`}
                    </li>
                  )}
                  <li className="list-group-item">
                    {" "}
                    Email : {`${user.Email}`}
                  </li>
                  <li className="list-group-item">
                    Wallet balance : {user.Wallet_balance}
                  </li>
                  <li className="list-group-item">
                    Withdrawal Amount balance : {user.withdrawAmount}
                  </li>
                  <li
                    className={`list-group-item ${
                      mismatchValue != 0 ? "text-danger" : ""
                    }`}
                  >
                    Withdrawal Hold balance : {user.withdraw_holdbalance}
                    <div className="col" style={{ color: "#8c8c8c" }}>
                      Note: If hold balance not 0 user can't make new Withdrawal
                      request
                    </div>
                  </li>
                  <li className="list-group-item">
                    Referral balance : {user.referral_wallet}
                  </li>
                  <li className="list-group-item">
                    Referral count : {referCount && referCount}
                  </li>
                  <li className="list-group-item">
                    Referral earning : {user.referral_earning}
                  </li>
                  <li className="list-group-item">
                    total Deposit : {user.totalDeposit}
                  </li>
                  <li className="list-group-item">
                    total Deposit Cashback : {user.depositCashback}
                  </li>
                  <li className="list-group-item">
                    total Withdrawal : {user.totalWithdrawl}
                  </li>
                  <li className="list-group-item">
                    total Bonus : {user.totalBonus}
                  </li>
                  <li className="list-group-item">
                    total Penalty : {user.totalPenalty}
                  </li>
                  <li className="list-group-item">
                    hold balance : {HoldBalance}
                  </li>
                  <li className="list-group-item">
                    Won amount : {user.wonAmount}
                  </li>
                  <li className="list-group-item">
                    Lose amount : {user.loseAmount}
                  </li>
                  <li className="list-group-item">
                    Calculated wallet balance :{" "}
                    {user.wonAmount -
                      user.loseAmount +
                      amountDeposit +
                      user.referral_earning +
                      user.totalBonus -
                      (user.totalWithdrawl +
                        user.referral_wallet +
                        user.withdraw_holdbalance +
                        user.hold_balance +
                        user.totalPenalty)}
                  </li>
                  <li className="list-group-item">
                    Mismatch wallet balance : {mismatchValue}
                  </li>
                  <li className="list-group-item">
                    Referral code : {user.referral_code}
                  </li>
                  {/* {(props.updateLegalData)  && ( */}
                  <li className="list-group-item">
                    Referral by : {user.referral}
                    {/* Referral by : {user.referral}  {user1?.user_type == 'Admin' || user1?.Permissions[29]?.Status === true ? <BiEditAlt onClick={Referal} />  : ""} */}
                  </li>
                  {/* )}  */}

                  <li className="list-group-item">
                    Account created at :{" "}
                    {dateFormat(user.createdAt).split(",")[0]}
                  </li>
                  <li className="list-group-item">
                    Account updated at :{" "}
                    {dateFormat(user.updatedAt).split(",")[0]}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="card shadow-lg">
                <div className="card-header">Bank Details</div>
                <ul className="list-group list-group-flush">
                  <li
                    className="list-group-item"
                    onClick={() => {
                      navigator.clipboard.writeText(user.holder_name);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Account holder name : {user.holder_name}
                  </li>
                  <li
                    className="list-group-item"
                    onClick={() => {
                      navigator.clipboard.writeText(user.ifsc_code);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    IFSC code : {user.ifsc_code}
                  </li>
                  <li
                    className="list-group-item"
                    onClick={() => {
                      navigator.clipboard.writeText(user.account_number);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Account number : {user.account_number}
                  </li>
                  <li className="list-group-item">
                    UPI ID :{" "}
                    {admindata?.user?.user_type == "Admin" ||
                    admindata?.user?.profile?._id ==
                      "65b788e5b34c045962581b27" ||
                    admindata?.user?.profile?._id ==
                      "63dc91a047315cbd7d903c01" ||
                    admindata?.user?.updateLegalData === true
                      ? user.upi_id
                      : ""}
                  </li>
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    Mismatch:{" "}
                    <button
                      onClick={() => updateMismatch(user._id)}
                      className="btn btn-success"
                    >
                      CLEAR ({mismatchValue})
                    </button>
                  </li>
                  <li className="list-group-item">
                    Hold :{" "}
                    <button
                      onClick={() => updateHold(user._id)}
                      className="btn btn-success"
                    >
                      CLEAR ({HoldBalance})
                    </button>{" "}
                  </li>
                  {/* {admindata?.user?.user_type == "Admin" ||
                  admindata?.user?.profile?._id == "65b799fa211fc2090577ec3e" ||
                  admindata?.user?.updateLegalData === true ? ( */}
                  <li className="list-group-item">
                    Add Fraud :{" "}
                    {parseFloat(user.checkFraud) === parseFloat(1) ? (
                      <button
                        onClick={() => updateCheckFraud(user._id, 0)}
                        className="btn btn-danger"
                      >
                        Remove Fraud
                      </button>
                    ) : (
                      <button
                        onClick={() => updateCheckFraud(user._id, 1)}
                        className="btn btn-danger"
                      >
                        Add Fraud
                      </button>
                    )}
                    {showFraud ? (
                      <button
                        onClick={() => setShowFraud(false)}
                        className="btn btn-danger mx-1"
                      >
                        Remove Fraud List
                      </button>
                    ) : (
                      <button
                        onClick={() => setShowFraud(true)}
                        className="btn btn-danger mx-1"
                      >
                        Show Fraud List
                      </button>
                    )}
                  </li>
                  {/* // ) : ( // "" // )} */}
                  {admindata?.user?.user_type == "Admin" ||
                  admindata?.user?.profile?._id == "65b788e5b34c045962581b27" ||
                  admindata?.user?.updateLegalData === true ? (
                    <li className="list-group-item">
                      Add Name Change :{" "}
                      {parseFloat(user.checkName) === parseFloat(1) ? (
                        <button
                          onClick={() => updateCheckNameChange(user._id, 0)}
                          className="btn btn-danger"
                        >
                          Remove Name Block
                        </button>
                      ) : (
                        <button
                          onClick={() => updateCheckNameChange(user._id, 1)}
                          className="btn btn-danger"
                        >
                          Add Name Block
                        </button>
                      )}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="list-group-item">
                    Status :
                    {user.user_type == "Block" ? (
                      <>
                        <span style={{ color: "red" }}>Block</span>
                        <br></br> <span>Reason: {user.lastmsg}</span>
                      </>
                    ) : (
                      <span style={{ color: "green" }}> Active </span>
                    )}
                  </li>
                  <li className="list-group-item">
                    Otp Remove :
                    <button
                      onClick={() => otpRemoveHanlder(user.Phone)}
                      className="btn btn-danger"
                    >
                      Remove Otp
                    </button>
                  </li>

                  {admindata?.user?.profile?.user_type == "Admin" ? (
                    <li className="list-group-item">
                      Check Balance :
                      <button
                        onClick={() => checkUserTotalValue()}
                        style={{ background: "green" }}
                        className="btn btn-danger"
                      >
                        Check Balance
                      </button>
                    </li>
                  ) : (
                    ""
                  )}

                  {admindata?.user?.profile?.user_type == "Admin" &&
                  showInputsTag ? (
                    <li className="list-group-item">
                      Change key Updates :
                      <button
                        style={{ background: "#c7a846" }}
                        className="btn btn-danger"
                        onClick={() => setShowInputs(!showInputs)}
                      >
                        Key Updates
                      </button>
                    </li>
                  ) : (
                    ""
                  )}

                  {showInputs && (
                    <div style={{ marginTop: "10px", padding: "10px 20px" }}>
                      <input
                        type="number"
                        name="Wallet_balance"
                        className="form-control"
                        placeholder="wallet balance"
                        value={formData.Wallet_balance}
                        onChange={handleInputChange}
                      />
                      <br />
                      <input
                        type="number"
                        name="withdrawAmount"
                        className="form-control"
                        placeholder="withdraw amount"
                        value={formData.withdrawAmount}
                        onChange={handleInputChange}
                      />

                      <button
                        className="btn btn-danger mt-3"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  )}

                  {kyc ? (
                    <li className="list-group-item">
                      Download Kyc{" "}
                      <button
                        onClick={() => generateKycPDF()}
                        className="btn btn-danger"
                      >
                        Download Kyc
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="card shadow-lg ">
                <div className="card-header">User Fraud Reason</div>
                {showFraud ? (
                  <ul className="list-group">
                    {fraudData &&
                      fraudData?.map((data, i) => (
                        <li className="list-group-item" key={i}>
                          {data?.txn_msg}
                        </li>
                      ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <button
                    className={
                      Boolean(challenge)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={() => {
                      Allchallenge();
                      setPageNumber(0);
                    }}
                  >
                    Game History
                  </button>

                  <button
                    className={
                      Boolean(txn)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={() => {
                      transactionHis();
                      setPageNumber(0);
                    }}
                  >
                    Deposit History
                  </button>
                  <button
                    className={
                      Boolean(txnwith)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={() => {
                      withdrawalHis();
                      setPageNumber(0);
                    }}
                  >
                    withdrawal History
                  </button>
                  <button
                    className={
                      Boolean(referral)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={() => {
                      referralHis();
                      setPageNumber(0);
                    }}
                  >
                    Referral History
                  </button>
                  <button
                    className={
                      Boolean(kyc)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={() => {
                      getKyc();
                      setPageNumber(0);
                    }}
                  >
                    KYC
                  </button>
                  <button
                    className={
                      Boolean(merged)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={() => {
                      commonApi();
                      setPageNumber(0);
                    }}
                  >
                    Merged
                  </button>
                  <button
                    className="btn btn-primary mb-3 mr-2"
                    onClick={() => {
                      all_history();
                      setPageNumber(0);
                    }}
                  >
                    All Histoty
                  </button>
                  {Boolean(challenge) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th> Creator</th>
                            <th> Accepter</th>
                            <th> Amount </th>
                            <th> Win/Lose Amt </th>
                            <th> closing balance</th>
                            <th> Status </th>
                            <th> Game Type </th>
                            <th> Winner </th>
                            <th> Date </th>
                            <th> Action </th>
                          </tr>
                        </thead>

                        <tbody>
                          {challenge &&
                            challenge?.map((game, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{game?._id ? game?._id : null}</td>
                                <td>
                                  <span
                                    className={`pl-2 ${
                                      parseFloat(
                                        game?.Created_by?.checkFraud
                                      ) === 1
                                        ? "text-danger"
                                        : ""
                                    }`}
                                  >
                                    {game.Created_by
                                      ? game.Created_by.Name
                                      : null}
                                  </span>
                                </td>

                                <td>
                                  <span
                                    className={`pl-2 ${
                                      parseFloat(
                                        game?.Accepetd_By?.checkFraud
                                      ) === 1
                                        ? "text-danger"
                                        : ""
                                    }`}
                                  >
                                    {game.Accepetd_By
                                      ? game.Accepetd_By.Name
                                      : null}
                                  </span>
                                </td>
                                <td>{game.Game_Ammount}</td>

                                <td>
                                  {game.Winner
                                    ? user?._id == game.Winner?._id
                                      ? "+" + game.winnAmount
                                      : "-" + game.Game_Ammount
                                    : user?._id == game.Created_by?._id
                                    ? game.winnAmount
                                    : "-" + game.winnAmount}
                                </td>

                                <td>
                                  {game.Winner
                                    ? user?._id == game.Winner?._id
                                      ? game.Winner_closingbalance
                                      : game.Loser_closingbalance
                                    : user?._id == game.Created_by?._id
                                    ? game.Loser_closingbalance
                                    : game.Winner_closingbalance}
                                </td>
                                <td className="text-primary font-weight-bold">
                                  {game.Status}
                                </td>
                                <td>{game.Game_type}</td>
                                <td>{game.Winner ? game.Winner.Name : null}</td>

                                <td>
                                  {moment(game.updatedAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </td>

                                <td>
                                  <Link
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    to={`/view/${game._id}`}
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div className="mt-4">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={numberOfPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}

                  {Boolean(txn) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th> Amount </th>
                            {/* <th> Bonus </th> */}
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Payment gatway </th>
                            <th>created At</th>
                            <th>updated At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {txn.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data._id}</td>

                              <td>{data.amount}</td>
                              {/* <td>{data.User_id.totalBonus}</td> */}
                              <td> {data.closing_balance}</td>
                              <td className="font-weight-bold text-success">
                                {data.status}
                              </td>
                              <td>
                                {" "}
                                {data.payment_gatway
                                  ? data.payment_gatway
                                  : data.txn_msg}
                              </td>
                              <td>
                                {moment(data.createdAt).format(
                                  "MMMM D [at] h:mm A"
                                )}
                              </td>
                              <td>
                                {moment(data.updatedAt).format(
                                  "MMMM D [at] h:mm A"
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => Invoice(data)}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="mt-4">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={numberOfPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                  {Boolean(txnwith) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th> type</th>
                            <th> Amount </th>
                            {/* <th> Penalty </th> */}
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Account Details </th>
                            <th> created At </th>
                            <th> updated At </th>
                          </tr>
                        </thead>
                        <tbody>
                          {txnwith &&
                            txnwith.map((data, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                <td>{data._id}</td>

                                <td>
                                  <span className="pl-2">
                                    {data?.upi_no}
                                    {data.Withdraw_type
                                      ? data.Withdraw_type
                                      : data.txn_msg}
                                  </span>
                                </td>
                                <td>{data.amount}</td>
                                {/* <td>{data.User_id.totalPenalty}</td> */}
                                <td> {data.closing_balance}</td>
                                <td className="font-weight-bold text-success">
                                  {data.status}
                                </td>
                                {/* <td>
                                  {
                                    //&& data.status != 'FAILED'
                                    data.status != "SUCCESS" &&
                                    data.amount == user.withdraw_holdbalance ? (
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          checkfailedpayout(
                                            data._id,
                                            data.referenceId
                                          )
                                        }
                                      >
                                        Check {data.payment_gatway}
                                      </button>
                                    ) : (
                                      "Checked"
                                    )
                                  }
                                </td> */}
                                <td>
                                  {data?.bank_details?.holder_name}{" "}
                                  {data?.bank_details?.account_number}{" "}
                                  {data?.bank_details?.ifsc_code}{" "}
                                </td>
                                <td>
                                  {moment(data.createdAt).format(
                                    "MMMM D [at] h:mm A"
                                  )}
                                </td>
                                <td>
                                  {moment(data.updatedAt).format(
                                    "MMMM D [at] h:mm A"
                                  )}{" "}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div className="mt-4">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={numberOfPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                  {Boolean(referral) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th> Earned by</th>
                            <th> Amount </th>
                            <th> Closing balance</th>
                            <th> Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          {referral.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td>{data.earned_from.Name}</td>
                              <td>{data.amount}</td>
                              <td>{data.closing_balance}</td>
                              {/* <td>{dateFormat(data.updatedAt).split(',')[0]} </td> */}
                              <td>
                                {moment(data.updatedAt).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="mt-4">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={numberOfPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                  {Boolean(kyc) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th>Doc name</th>
                            <th> Aadhar no</th>

                            <th>DOB</th>
                            <th> Document-Front</th>
                            <th> Document-Back</th>
                            <th> status </th>
                            <th>Date</th>
                            <th> Approve or Reject </th>
                            {/* <th> View </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {kyc &&
                            kyc.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item._id}</td>

                                <td>{item.Name}</td>

                                <td>{item.Number}</td>
                                <td>{item.DOB}</td>
                                <td>
                                  {item?.type === "online" ? (
                                    item?.Address
                                  ) : (
                                    <div className="img-panel">
                                      <img
                                        className="img"
                                        src={`${item.front}`}
                                        alt="kyc"
                                        style={{
                                          borderRadius: "5px",
                                          width: "4rem",
                                          height: "4rem",
                                        }}
                                        id={`kyc${index}`}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {item?.type === "online" ? (
                                    ""
                                  ) : (
                                    <div className="img-panel">
                                      <img
                                        className="img"
                                        src={`${item.back}`}
                                        alt="kyc"
                                        style={{
                                          borderRadius: "5px",
                                          width: "4rem",
                                          height: "4rem",
                                        }}
                                        id={`kyc${index}`}
                                      />
                                    </div>
                                  )}
                                </td>

                                <td>
                                  <span
                                    className={
                                      item.verified === "reject"
                                        ? "btn btn-danger"
                                        : "btn btn-success"
                                    }
                                  >
                                    {item.verified}
                                  </span>{" "}
                                </td>
                                <td>
                                  {/* {dateFormat(item.updatedAt).split(",")[0]}{" "} */}
                                  <td>
                                    {moment(item?.updatedAt).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </td>
                                </td>
                                <td>
                                  {item?.type === "online" ? (
                                    ""
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-success mr-1"
                                        disabled={
                                          item.verified === "verified"
                                            ? true
                                            : false
                                        }
                                        onClick={() => update(item._id)}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn btn-danger mr-1"
                                        disabled={
                                          item.verified === "verified"
                                            ? false
                                            : false
                                        }
                                        onClick={() => deletedata(item._id)}
                                      >
                                        Reject
                                      </button>
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => handleDelete(item._id)}
                                      >
                                        Force delete
                                      </button>
                                    </>
                                  )}
                                </td>
                                {/* <td><Link to="/user/view" className="btn btn-primary">View</Link></td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(merged) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th> Amount </th>
                            <th>Win/Loose Amt</th>
                            <th>Closing Balance</th>
                            <th> Status</th>
                            <th> Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {merged.map((data, key) => {
                            // console.log(data.Game_Amount)
                            return (
                              <tr
                                key={key}
                                style={{
                                  color:
                                    data.Req_type == "deposit"
                                      ? "green"
                                      : data.Req_type == "withdraw"
                                      ? "		#ff7f50"
                                      : "black",
                                }}
                              >
                                <td>{key + 1}</td>
                                <td>{data.ID}</td>
                                <td>{data.amount}</td>
                                <td>{data.Win_loose_amount}</td>
                                <td>{data.closing_balance}</td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      data.status == "SUCCESS"
                                        ? "seagreen"
                                        : data.status == "completed"
                                        ? "royalblue"
                                        : data.status == "cancelled"
                                        ? "salmon"
                                        : data.status == "FAILED"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {data.status}
                                  {data.status == "PAID" ? (
                                    <span>&#9989;</span>
                                  ) : data.status == "FAILED" ? (
                                    <span>&#10060;</span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                {/* <td>{data.date} </td> */}
                                <td>
                                  {moment(data.updatedAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="mt-4">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={numberOfPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
