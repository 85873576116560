import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import base_Url from "../BaseUrl";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyBbaMSiVY1p5EMMsa47gbSov-hl7utaKTI",
  authDomain: "mjludo-678f3.firebaseapp.com",
  projectId: "mjludo-678f3",
  storageBucket: "mjludo-678f3.appspot.com",
  messagingSenderId: "1037267777406",
  appId: "1:1037267777406:web:323acbbecd343daf02652f",
};

const access_token = localStorage.getItem("mjLudoTokenDash");
const fcm = localStorage.getItem("fcm");

export const getTokens = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BAKF6ZZqgEZPohPpUueA0S3av1cFRbwsSyw2z8xXUAlkUYQImjHxJAmKGDUIbl4oDV4QnemZWh5Yu-JX9DdJfVM",
  })
    .then((currentToken) => {
      if (currentToken) {
        if (access_token && fcm != currentToken) {
          const config = {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          };
          axios
            .get(`${base_Url}update-fcm-token?token=${currentToken}`, config)
            .then((res) => {
              localStorage.setItem("fcm", currentToken);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        setTokenFound({ status: true, token: currentToken });
      } else {
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// async function loginWithGoogle() {
//     try {
//         const provider = new GoogleAuthProvider();
//         const auth = getAuth();

//         const { user } = await signInWithPopup(auth, provider);

//         return { uid: user.uid, displayName: user.displayName };
//     } catch (error) {
//         if (error.code !== 'auth/cancelled-popup-request') {
//             console.error(error);
//         }

//         return null;
//     }
// }
// const provider=JSON.parse(localStorage.getItem('provider'));
//console.log(provider?.provider_data?.id)
// async function sendMessage(roomId, user, text) {
//     try {
//         await addDoc(collection(db, 'chatRoom', roomId, 'chats'), {
//             //   uid: user.uid,
//             //   displayName: user.displayName,
//             //   text: text.trim(),
//             //   timestamp: serverTimestamp(),
//             uid: user?.data?.id || null,
//             displayName: user?.data?.display_name || null,
//             user_type:user?.data?.user_type,
//             reciver_id:provider?.provider_data?.id,
//             text: text.trim(),
//             timestamp: serverTimestamp(),
//         });

//     } catch (error) {
//         console.error(error);
//     }
// }

// function getMessages(roomId, callback) {
//     return onSnapshot(
//         query(
//             collection(db, 'chatRoom', roomId, 'chats'),
//             orderBy('timestamp', 'asc')
//         ),
//         (querySnapshot) => {
//             const messages = querySnapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 ...doc.data(),
//             }));
//             console.log(messages)
//             callback(messages);
//         }
//     );
// }

// export { loginWithGoogle, sendMessage, getMessages };
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const db = getFirestore(app);
