import React, { useState, useEffect } from "react";
import "./PinMatch.css";
import Cookies from "js-cookie";

const PinMatch = () => {
  const [generatedPin, setGeneratedPin] = useState(9051);
  const [submittedPin, setSubmittedPin] = useState("");
  const [attempt, setAttempt] = useState(3);
  const [notification, setNotification] = useState("");

  const handlePinGenerator = () => {
    const pin = Math.floor(1000 + Math.random() * 9000);
    setGeneratedPin(pin);
    setSubmittedPin("");
  };

  const handleButtonPress = (btnLabel) => {
    if (btnLabel === "<") {
      setSubmittedPin((prev) => prev.slice(0, -1));
    } else if (btnLabel === "C") {
      setSubmittedPin("");
    } else {
      if (submittedPin.length < 4) {
        setSubmittedPin((prev) => prev + btnLabel);
      }
    }
  };

  const attemptLocal = localStorage.getItem("attempt");

  useEffect(() => {
    if (!attemptLocal) {
      localStorage.setItem("attempt", 3);
    }
  }, []);

  const encode = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
  };

  const handleSubmit = () => {
    if (generatedPin > 0 && submittedPin.length > 0) {
      setAttempt((prev) => prev - 1);

      if (parseInt(submittedPin) !== generatedPin) {
        if (localStorage.getItem("attempt") < 2) {
          Cookies.set("blockuser", "sure", { expires: 1 });
          localStorage.setItem("removeUser", "yes");
        }
        setNotification("notmatched");
        localStorage.setItem("attempt", attempt - 1);
        setTimeout(() => setNotification(""), 3000);
        setSubmittedPin("");
      } else {
        setNotification("matched");
        const thisValue = encode("IAmMjAccessUserFind");
        localStorage.setItem("yesIAmFind", thisValue);
        setTimeout(() => setNotification(""), 3000);
        window.location.reload();
      }
    }

    if (attempt === 1) {
      // Disable submit button if no attempts left
      // This can be handled via state in the JSX
    }
  };

  const accessToPanel = Cookies.get("blockuser");
  const accessToPanelLocal = localStorage.getItem("removeUser");

  const resetAll = () => {
    setGeneratedPin(0);
    setSubmittedPin("");
    setAttempt(3);
  };

  return (
    <>
      {accessToPanel === "sure" || accessToPanelLocal === "yes" ? (
        <div className="text-center mt-5">
          <h2>You have been blocked forever</h2>
        </div>
      ) : (
        <div className="container pinmatch">
          <h2 className="text-center display-4 text-secondary mb-5">
            Access To Panel Please Enter Pin
          </h2>
          <div className="row">
            {/* <div className="col-md-6">
            <div className="pin-generator half-width">
              <input
                className="form-control"
                type="text"
                value={generatedPin > 0 ? generatedPin : ""}
                disabled
              />
              <button className="generate-btn" onClick={handlePinGenerator}>
                Generate Pin
              </button>
            </div>
          </div> */}
            <div className="notify-section mb-3">
              {notification === "notmatched" && (
                <p className="notify notmatched active">
                  ❌ &nbsp; Pin Didn't Match, Please try again
                </p>
              )}
              {notification === "matched" && (
                <p className="notify matched active">
                  ✅ &nbsp; Pin Matched... Secret door is opening for you
                </p>
              )}
            </div>
            <div className="col-md-12">
              <div className="input-section half-width mt-md-0 mt-4">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Verify pin"
                  value={submittedPin}
                  disabled
                />
                <div className="numbers">
                  <div className="calc-body">
                    <div className="calc-button-row">
                      {["7", "8", "9"].map((btn) => (
                        <div
                          key={btn}
                          className="button"
                          onClick={() => handleButtonPress(btn)}
                        >
                          {btn}
                        </div>
                      ))}
                    </div>
                    <div className="calc-button-row">
                      {["4", "5", "6"].map((btn) => (
                        <div
                          key={btn}
                          className="button"
                          onClick={() => handleButtonPress(btn)}
                        >
                          {btn}
                        </div>
                      ))}
                    </div>
                    <div className="calc-button-row">
                      {["1", "2", "3"].map((btn) => (
                        <div
                          key={btn}
                          className="button"
                          onClick={() => handleButtonPress(btn)}
                        >
                          {btn}
                        </div>
                      ))}
                    </div>
                    <div className="calc-button-row">
                      {["<", "0", "C"].map((btn) => (
                        <div
                          key={btn}
                          className="button"
                          onClick={() => handleButtonPress(btn)}
                        >
                          {btn}
                        </div>
                      ))}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="submit-btn"
                        onClick={handleSubmit}
                        disabled={localStorage.getItem("attempt") < 1}
                      >
                        Submit
                      </button>
                      <p className="action-left">
                        Attempt left {attemptLocal || attempt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PinMatch;
