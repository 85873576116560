import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import admindata from "../../components/admindata";
import Loader from "react-js-loader";
const $ = require("jquery");
$.Datatable = require("datatables.net");
export default function Bonus() {
  const [data, setUser] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [loading, setLoading] = useState(false);

  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  //   searching handler
  const searchHandler = (event) => {
    let key = event.target.value;
    setSearchList(key);
  };

  const Alluser = () => {
    setLoading(true);
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `User/all/panelty?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}`,
        { headers }
      )
      .then((res) => {
        //setUser(res.data)
        setLoading(false);
        setUser(res.data.admin);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
      });
  };

  const [type, setType] = useState();
  const [bonus, setBonus] = useState();
  const update = async (id) => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // if (type === "bonus") {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Bonus Reason",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
    });

    if (text) {
      axios
        .post(
          baseUrl + `user/bonus/${id}`,
          {
            bonus: JSON.parse(bonus),
            reason: text,
          },
          { headers }
        )
        .then((res) => {
          Alluser();
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Enter Reason",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // }
  };

  useEffect(() => {
    Alluser();
  }, [pageNumber, limit, searchList, searchType]);

  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body text-light">
              <h4 className="card-title ">Bonus</h4>
              {/* searching */}
              <div className="row">
                <div className="col-md-4">
                  <select
                    className="form-control col-sm-3 m-2"
                    id="searchType"
                    name="searchtype"
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="0">Select Search by</option>
                    <option value="Name">Name</option>
                    <option value="Phone">Phone</option>
                    <option value="_id">User Id</option>
                    <option value="userUniqueId">Unique Id</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control col-sm-4 m-2"
                    onChange={searchHandler}
                  />
                </div>
                <div className="col-md-1 ms-auto">
                  <select
                    className="form-control col-sm-1 m-1 bg-dark text-light"
                    id="pagelimit"
                    name="pagelimit"
                    onChange={setpageLimit}
                  >
                    <option value="10">Set limit</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> ID</th>
                      <th> Name</th>
                      <th> Mobile </th>
                      <th> Balance </th>
                      <th> Action </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                      data.map((data, key) => (
                        <tr role="row" className="odd">
                          <td className="sorting_1">{key + 1}</td>
                          <td>{data._id}</td>
                          <td>{data.Name}</td>
                          {admindata?.user?.user_type == "Admin" ||
                          admindata?.user?.updateLegalData === true ? (
                            <td>{data.Phone}</td>
                          ) : (
                            <td>{`${
                              "********" + data.Phone.toString().slice(-2)
                            }`}</td>
                          )}
                          <td>{data.Wallet_balance}</td>
                          <td>
                            <div className="row">
                              <div className="col-12 col-lg-5">
                                <input
                                  id="number"
                                  type="number"
                                  className="form-control input-sm"
                                  style={{ minWidth: "100px" }}
                                  placeholder="Amount"
                                  onChange={(e) => setBonus(e.target.value)}
                                />
                              </div>

                              <div className="col-12 col-lg-3">
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={() => update(data._id)}
                                >
                                  UPDATE
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item previous"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item next"}
                  nextLinkClassName={"page-link active"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
