import React, { Component, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import axios from "axios";
import { useDispatch } from "react-redux";
import { onUsers } from "../../redux/homeAction";

const Navbar = ({ usertype, userAname }) => {
  const dispatch = useDispatch();
  const access_token = localStorage.getItem("mjLudoTokenDash");

  useEffect(() => {
    if (access_token) {
      dispatch(onUsers(access_token));
    }
  }, []);

  const logout = () => {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }

    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("mjLudoTokenDash", "user");
        window.location.reload();
        window.location.assign("/adminlogin");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("mjLudoTokenDash", "user");
          localStorage.removeItem("mjLudoTokenDash", "user");
          window.location.assign("/adminlogin");
        }
      });
  };

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      {/* <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </Link>
      </div> */}
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          style={{ outline: "none" }}
        >
          {/* <span className="mdi mdi-menu"></span> */}
          <img
            src="/menu-bar.png"
            alt="icon"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          />
        </button>

        <img
          src="/menu-bar.png"
          alt="icon"
          onClick={toggleOffcanvas}
          className="icon-show"
        />
        <ul className="navbar-nav w-100">
          <li className="nav-item w-100" style={{ textAlign: "end" }}>
            <h6>{userAname}</h6>
          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          <Dropdown as="li" className="nav-item">
            <Dropdown.Toggle
              as="a"
              className="nav-link cursor-pointer no-caret"
            >
              <div className="navbar-profile">
                <img
                  className="img-xs rounded-circle"
                  src="https://cdn-icons-png.flaticon.com/512/2206/2206368.png"
                  alt="profile"
                />

                <p className="mb-0 d-none d-sm-block navbar-profile-name">
                  <Trans>User: {usertype}</Trans>
                </p>
                <i className="mdi mdi-menu-down d-none d-sm-block"></i>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
              <Dropdown.Item onClick={() => logout()} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-logout text-danger"></i>
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1 text-dark">
                    <Trans>Log Out</Trans>
                  </p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="preview-item">
                <Link to={"/admin/update"} className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-update text-danger"></i>
                  </div>
                </Link>
                <Link to={"/admin/update"} className="preview-item-content">
                  <p className="preview-subject mb-1 text-dark">
                    <Trans>Update Password</Trans>
                  </p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="preview-item">
                <Link to={"/admin/profile"} className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-account-circle text-danger"></i>
                  </div>
                </Link>
                <Link to={"/admin/profile"} className="preview-item-content">
                  <p className="preview-subject mb-1 text-dark">
                    <Trans>Profile</Trans>
                  </p>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
