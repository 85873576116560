import { ONUSERS, ONUSERSSITESETTING } from "./type";

const initialState = {};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONUSERS:
      return {
        ...state,
        getOnUsers: action.payload,
      };
    case ONUSERSSITESETTING:
      return {
        ...state,
        getOnUsersSiteSetting: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default homeReducer;
