import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import "./invoice.css";

const LetterHeadCompany = () => {
  const invoiceRef = useRef();
  const generatePDF = () => {
    const element = invoiceRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 0.1,
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all"] },
      })
      .save();
  };

  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body text-light">
              <h4 className="card-title">Company Letter Head</h4>

              <button
                type="button"
                className="btn btn-success"
                onClick={generatePDF}
              >
                Print
              </button>

              <div style={{ padding: 20 }}></div>
              <div className="table-responsive">
                <div ref={invoiceRef}>
                  <div
                    className="mb-5 mt-5 pt-5 pb-5"
                    style={{ background: "#fff", color: "#000" }}
                  >
                    <div className="invoice-border">
                      <div className="invoice-flex">
                        {/* <div className="invoice-img">
                          <img src="/mjludologo.png" alt="logo" />
                        </div> */}
                        {/* <div className="invoice-h1">
                          <h1>Tax Invoice</h1>
                        </div> */}
                      </div>
                      <h2 className="invoice-h2">
                        SYNTHLOOM SOLUTIONS PRIVATE LIMITED
                      </h2>
                      <h3 className="invoice-h3">GSTN: 08ABMCS4675F1ZV</h3>
                      <h4 className="invoice-h4">
                        Address: Plot No. F-4, Nandpuri Colony,sodala, Shyam
                        Nagar (jaipur), Jaipur, Jaipur, Rajasthan, India, 302019
                      </h4>
                    </div>
                    {/* <div
                      class="invoice-border  pt-5 pb-5"
                      style={{ color: "#000" }}
                    >
                      <h2
                        className="text-center mb-5"
                        style={{
                          fontWeight: "700",
                          textTransform: "uppercase",
                        }}
                      >
                        Board Resolution
                      </h2>

                      <div class="content" style={{ color: "#000" }}>
                        <p className="mb-5">
                          Certified true copy of the resolution passed at the
                          meeting of the committee of Directors of the Company
                          held on{" "}
                          <strong>{new Date().toLocaleDateString()}</strong>
                        </p>

                        <p className="mb-5">
                          "Resolved that the consent of the Board be and is
                          hereby accorded to enter into an agreement with{" "}
                          <strong>PayU Payments Private Limited</strong>, Delhi
                          for availing of payment gateway services on terms and
                          conditions as specified in the agreement."
                        </p>

                        <p className="mb-5">
                          Further resolved that <strong>Mahak Saini</strong> of
                          the company be and hereby severally authorized to sign
                          and execute on behalf of the company, all agreements,
                          undertakings, and any other documents that may be
                          necessary for availing the said services from{" "}
                          <strong>PayU Payments Private Limited</strong>. And to
                          do all such acts that may be necessary to implement
                          the foregoing resolution.
                        </p>

                        <p className="mb-5">
                          For{" "}
                          <strong>SYNTHLOOM SOLUTIONS PRIVATE LIMITED</strong>
                        </p>
                      </div>
                      <div
                        class="signature-section mb-5"
                        style={{ color: "#000" }}
                      >
                        <div
                          class="signature-block mb-5"
                          style={{ color: "#000" }}
                        >
                          <p>
                            1. <strong>MAHAK SAINI (Director)</strong>
                          </p>
                        </div>
                        <div
                          class="signature-block mb-5"
                          style={{ color: "#000" }}
                        >
                          <p>
                            2. <strong>NANDNI CHOUHAN (Director)</strong>
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className="invoice-border pt-5 pb-5"
                      style={{ color: "#000" }}
                    >
                      <h3 style={{ color: "#000" }}>Declaration</h3>
                      <p className="pt-3 pb-3">
                        This is to declare that the Credit Balance (CB) was
                        wrongly claimed by the Claim Holder (CH), and it should
                        be rectified. Please take necessary actions to correct
                        this error.
                      </p>
                      <p className="pt-3 pb-3">
                        We have added the money of these users to their user ID.
                        This user entered a wrong dispute and has committed
                        fraud.
                      </p>
                      <p className="pt-3 pb-3">
                        We request that the above-mentioned error be addressed
                        at the earliest convenience.
                      </p>

                      <p className="pt-3 pb-3">Thank you.</p>

                      <p className="pt-3 pb-3">
                        Date: {new Date().toLocaleDateString()}
                      </p>
                    </div>
                    <div className="invoice-border pt-5 pb-5">
                      <h4 className="text-center">
                        This is computer generated Invoice, hence signature is
                        not required.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LetterHeadCompany;
