import React, { useState } from "react";
import Conflictgame from "./Conflictgame";
import axios from "axios";

const DashGame = () => {
  const [COMPLETED, setCOMPLETED] = useState(null);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const comp = () => {
    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `challange/completed`, { headers }).then((res) => {
      setCOMPLETED(res.data);
    });
  };

  return (
    <>
      <div className="">
        <Conflictgame />
        <div className="row">
          <h2 className="box-desing-h2">Today Game Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total Game</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Cancel Game</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Success Game</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Pending Game</h3>
              <span>4099878</span>
            </div>
          </div>
        </div>
        <div className="row">
          <h2 className="box-desing-h2">Total Game Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total Game</h3>
              <span>{COMPLETED && COMPLETED}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Cancel Game</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Success Game</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Pending Game</h3>
              <span>4099878</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashGame;
