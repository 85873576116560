import React from "react";

const DashUser = () => {
  return (
    <>
      <div className="">
        <div className="row">
          <h2 className="box-desing-h2">Admin Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total Admin</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total Agent</h3>
              <span>4099878</span>
            </div>
          </div>
        </div>
        <div className="row">
          <h2 className="box-desing-h2">Today User Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total User</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Kyc Pending</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Kyc Success</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Kyc Reject</h3>
              <span>4099878</span>
            </div>
          </div>
        </div>
        <div className="row">
          <h2 className="box-desing-h2">Total User Details :-</h2>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Total User</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Kyc Pending</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Kyc Success</h3>
              <span>4099878</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="box-desing">
              <h3>Kyc Reject</h3>
              <span>4099878</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashUser;
