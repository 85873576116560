import React, { useState } from "react";
import DashGame from "./DashGame";
import DashDeposit from "./DashDeposit";
import DashUser from "./DashUser";
import DeshWithdraw from "./DeshWithdraw";
import "react-datepicker/dist/react-datepicker.css";

const Dashboard2 = () => {
  const [value, setValue] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  function filterData() {
    // todayApi(new Date(startDate), new Date(endDate));
  }

  function daterange(s, e) {
    if (s > e) {
      alert("Starting date is greater than ending date");
    } else {
      setStartDate(s);
      setEndDate(e);
    }
    // Change start date format
    var startDate = new Date(s);
    var startDay = startDate.getDate();
    var startMonth = startDate.getMonth() + 1; // Months are zero-based
    var startYear = startDate.getFullYear();
    var startHours = startDate.getHours();
    var startMinutes = startDate.getMinutes();
    var startSeconds = startDate.getSeconds();

    // Change end date format
    var endDate = new Date(e);
    var endDay = endDate.getDate();
    var endMonth = endDate.getMonth() + 1; // Months are zero-based
    var endYear = endDate.getFullYear();
    var endHours = endDate.getHours();
    var endMinutes = endDate.getMinutes();
    var endSeconds = endDate.getSeconds();
  }

  return (
    <>
      <div className="">
        <div style={{ display: "flex" }}>
          <div className="form-group mx-sm-3 mb-2">
            <label htmlFor="startDate" className="sr-only">
              Start Date
            </label>
            <input
              type="datetime-local"
              id=""
              name=""
              className="form-control"
              value={startDate}
              onChange={(e) => daterange(e.target.value, endDate)}
            ></input>
            {/* <DatePicker
                selected={startDate}
              
                selectsStart
                startDate={startDate}
                endDate={endDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="yyyy-MM-dd HH:mm"
                className="form-control"
                id="startDate"
              /> */}
          </div>
          <h5>To</h5>
          <div className="form-group mx-sm-3 mb-2">
            <label htmlFor="endDate" className="sr-only">
              End Date
            </label>
            <input
              type="datetime-local"
              id=""
              name=""
              className="form-control"
              value={endDate}
              onChange={(e) => daterange(startDate, e.target.value)}
            ></input>
          </div>
          <button className="btn btn-success" onClick={filterData}>
            Submit
          </button>
        </div>
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        >
          {value ? "" : <option selected>Open this select menu</option>}
          <option value="game">All Game Details</option>
          <option value="user">All User Details</option>
          <option value="deposit">All Deposit Details</option>
          <option value="withdraw">All Withdraw Details</option>
        </select>
        {value === "game" && <DashGame />}
        {value === "user" && <DashUser />}
        {value === "deposit" && <DashDeposit />}
        {value === "withdraw" && <DeshWithdraw />}
      </div>
    </>
  );
};

export default Dashboard2;
