import { toast } from "react-toastify";
import Axios from "./axios";
import { ONUSERS, ONUSERSSITESETTING } from "./type";
import { validationFunction } from "../Validation";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("mjLudoTokenDash")}`,
  },
};

export const onUsers = (access_token) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  Axios.get("/me/admin", config)
    .then((res) => {
      dispatch({
        type: ONUSERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mjLudoTokenDash");
        window.location.reload();
        // history.pushState("/login")
      }
    });
};
export const onUsersSiteSetting = () => (dispatch) => {
  Axios.get("/settings/data/admin", config)
    .then((res) => {
      dispatch({
        type: ONUSERSSITESETTING,
        payload: validationFunction(res.data),
      });
    })
    .catch((err) => {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mjLudoTokenDash");
        window.location.reload();
        // history.pushState("/login")
      }
    });
};
